import gitInfo from 'git-info.json';
export const environment = {
  appVersion: gitInfo.tag || 'n/a',
  production: false,
  envName: 'local',
  devToolsEnabled: true,

  apiBaseUrl: 'https://dev-api.wingstop.com',
  apiBaseUrlMultiPort: false,

  gmapKey: 'AIzaSyCz7GW52B5Q4WD5Rd2suvEgWikLTH0CSHQ',
  gmapGeocodeApi: 'https://maps.googleapis.com/maps/api/geocode/json',
  recaptchaKey: '6Le_mVwUAAAAAO684sFDdewsQg7Ly7hB6SNlWSl9',

  hiddenLocationExtRefs: <string[]>[],

  // google optimize
  googleOptimizeId: 'OPT-WFHXSNH',

  // Social
  googleOauthClientId:
    '132749923255-pselncufrk5la62ncns65a63vlrg1phi.apps.googleusercontent.com',
  facebookAppId: '517887671946807',

  globalMenuId: 60819,

  orderWindow: { lower: -2, upper: +3 }, // for ASAP order 'your order will be ready in ...'

  emptyProductImage: '/assets/images/logo-green-product.png',

  productImageId: 'mobile-webapp-menu',
  categoryImageId: 'mobile-webapp-customize',

  buttonLabel: 'GOT IT',

  baseAboutSection:
    '<p>There’s nothing better than chicken wings straight to your doorstep, especially when a [STATE]' +
    ' Wingstop perfects your order through our order-ahead experience. Can’t wait? Have your made-to-order wings ready ' +
    'and hot right when you walk in our doors. Customize your order by choosing from our 11 signature flavors. From ' +
    'legendary Lemon Pepper, to the Buffalo style Original Hot or the bold, rich sauce of Hickory Smoked BBQ, our' +
    ' iconic flavors are always made-to-order and sauce and tossed to perfection. You can keep it savory, sweet, ' +
    'spicy or all the above at your [STATE] Wingstop locations.</p>' +
    '<p>The first franchised Wingstop location opened in 1997, and by 2002 we had served the world one billion wings.' +
    ' It’s flavor that defines us and has made Wingstop one of the fastest growing restaurant brands. Wingstop is proud to serve up flavor in [STATE].</p>' +
    '<p>Wingstop is the destination when you crave freshly-made wings, hand-cut seasoned fries and any of our famous ' +
    "sides like Cajun Fried Corn or Buffalo Ranch Fries. For people who demand flavor in everything they do, there's" +
    " only Wingstop - because it's more than a meal, it's a flavor experience.</p><b>BRING THE FLAVOR™</b>",
  cityAboutSection:
    '<p>At Wingstop in [CITY], our devoted Wing Experts don’t mess around. Every order is ensured to be drippin’ in flavor,' +
    ' and all wings are hot and made-to-order with a variety of dips for dunkin’. Pair your wings with our famous, made-from-scratch ranch dipping sauce,' +
    ' bleu cheese and more. With 11 signature flavors – from our legendary Lemon Pepper to the sweetened up heat of Mango Habanero – you have the option of' +
    ' boneless or classic wings, as well as tenders, all sauced and tossed and available with hand-cut, seasoned fries and our other signature sides like ' +
    'Cajun' +
    ' Fried Corn or Buffalo Ranch Fries.' +
    '</p>' +
    '<p>At Wingstop in [CITY], getting hot, freshly-made and flavorful wings is as easy as a few clicks. Place a carryout order at the Wingstop nearest you, ' +
    'or get it delivered straight to your doorstep at participating delivery restaurants.\n' +
    '</p>' +
    '<p>For [CITY] chicken wings that satisfy the crave, choose Wingstop.\n' +
    '</p>' +
    '<b>BRING THE FLAVOR™</b>',

  locationSearchPlaceholder: 'Search Zip or City, State',
  locationPageAboutSection:
    '<p>When you’re craving insane flavor and customizable wings, [Store] is the place to go in [City], [State]. With over 11 iconic' +
    ' flavors, our cooked-to-order wings will satisfy any craving. From Lemon Pepper to Original Hot, our savory flavors will leave you ' +
    'satisfied after every order.</p>' +
    '<p>At [Store], getting wings exactly how you like em’ is seamless. Order online for carryout and delivery from [Store] ' +
    'to get your hands on our classic or boneless wings as well as our tenders. For Buffalo wings, try our Original Hot or Mild flavors. Hungry for ' +
    'a signature classic? You’ll love our legendary Lemon Pepper or Louisiana Rub. Whatever flavors you like, pair them with our hand-cut,' +
    ' seasoned fries and ' +
    'other signature sides like Cajun Fried Corn or Buffalo Ranch Fries.</p>' +
    "<p>Since setting up shop in '94, we've been putting flavor first. And we're not about to stop. Our proprietary recipes," +
    ' outstanding food and superior guest experience created a demand that could only be satisfied by more locations. In 1997, we began offering franchises, ' +
    'and today we are one of the fastest-growing concepts in the country, attracting top entrepreneurs, operators and brand partners with the drive and ' +
    'passion to own their own business.</p> <b>BRING THE FLAVOR™</b>',

  states: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },

  gtagScript: `
     <!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-T4RLN6RS');
    <!-- End Google Tag Manager -->`,

  // OneTrust privacy scripts
  oneTrustPrivacyId: 'otprivacy-notice-script',
  oneTrustPrivacySource:
    'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
  oneTrustPrivacyScript: `
    window.checkExist = setInterval(function() {
      if (window.OneTrust && window.OneTrust.NoticeApi && window.OneTrust.NoticeApi.Initialized) {
        clearInterval(checkExist);
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-eu-cdn.onetrust.com/607a2afe-aa1a-4dfd-8b63-a64b4d563981/privacy-notices/draft/573de22f-eb1a-4eaa-b365-01508b2c9a54.json"], false);
        });
      }
    }, 100);
  `,
  oneTrustPrivacySettings: '',
  // OneTrust Terms of Use scripts
  oneTrustTermsId: 'otprivacy-notice-script',
  oneTrustTermsSource:
    'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
  oneTrustTermsScript: `
    window.checkExist = setInterval(function() {
      if (window.OneTrust && window.OneTrust.NoticeApi && window.OneTrust.NoticeApi.Initialized) {
        clearInterval(checkExist);
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-eu-cdn.onetrust.com/607a2afe-aa1a-4dfd-8b63-a64b4d563981/privacy-notices/draft/6f038a3c-8051-4725-93c9-ee9fc18e5755.json"], false);
        });
      }
    }, 100);
  `,
  oneTrustTermsSettings: '',
  // California Consumer Privacy Policy scripts
  caliConsumerPrivacyId: 'otprivacy-notice-script',
  caliConsumerPrivacySource:
    'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
  caliConsumerPrivacyScript: `
    window.checkExist = setInterval(function() {
      if (window.OneTrust && window.OneTrust.NoticeApi && window.OneTrust.NoticeApi.Initialized) {
        clearInterval(checkExist);
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-eu-cdn.onetrust.com/607a2afe-aa1a-4dfd-8b63-a64b4d563981/privacy-notices/draft/16fe1a38-4e67-44f9-9f22-cba80c8b038b.json"], false);
        });
      }
    }, 100);
  `,
  caliConsumerPrivacySettings: '',
  interactionStudioDataEnabled: true,
  epsilonRTI: 'https://s-usweb.dotomi.com/renderer/spd/global/spd.js',

  applePay: {
    merchantId: 'merchant.com.wingstop.applepay.test',
  },
  defaultCareerUrl: 'https://www.wingstopfeedback.smg.com/USA/?source=application',
  pilotUrl: 'https://demo.sit.ordering.wingstop.com',
  pilotUrl2: 'https://demo-hotfix.sit.ordering.wingstop.com',
  pilotUrl3: 'https://demo-hotfix.sit.ordering.wingstop.com',
  hostname2: 'dev2.wingstop.com',
  hostname3: 'dev2.wingstop.com',
  pingAuthCookieName: 'pingAuth',
  pingProfileCookieName: 'pingAuthProfile',
  pingCookieDomain: 'wingstop.com',

  ecomm: {
    baseUrl: 'https://ecomm.env1.us.regional.sit.cf.10piececombo.com',
    menuUrl: 'https://ecomm.env1.us.regional.sit.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.env1.us.regional.sit.cf.10piececombo.com'
  },
  featureFlags: {
    enableApplePay: true,
    enableDevLogs: true,
    enableVenmo: true,
    enablePaypal: true,
    enableGooglePay: true,
    enableGlobalMenu: true,
    /** Feature flags related to /order route */
    enableOrderRoute: true,
    /** **************************** */
    enableReorder: true,
    enableHomePage: true,
    enableFlavorsPage: true,
    enableCancelOrder: true,
    enableSpanishTranslation: true,
    enableEatStoreMenuUrl: true,
    enableVaultedGiftCardPay: true,
    enableVaultedGiftCardView: true,
    enableVaultedGiftCardVault: true,
    enableVaultedCreditCardPay: true,
    enableVaultedCreditCardView: true,
    enableVaultedCreditCardVault: true
  },
  brainTree: {
    authorization: 'sandbox_q75xqm98_bmtnfwpr58nscs2z',
    merchantAccountId: 'wingstop',
    urls: [
      'https://js.braintreegateway.com/web/3.97.1/js/client.min.js',
      'https://js.braintreegateway.com/web/3.97.1/js/venmo.min.js',
      'https://js.braintreegateway.com/web/3.97.1/js/data-collector.min.js',
      'https://js.braintreegateway.com/web/3.97.1/js/paypal-checkout.min.js',
    ],
    useFakeNonce: true
  },
  fiserv: {
    apiKey: 'eKPIX1bgGQGR1jkYDKO556bh9TYGAOhG',
    env: 'int',
    sdkUri: 'https://int.api.firstdata.com/ucom/v2/static/v2/js/ucom-sdk.js',
    ravelinApiKey: 'publishable_key_test_liveus_Vr7SqPaa304GlR3shAASG6QU2b4d0OQThWaI0Q'
  },
  grecaptcha: {
    siteKey: '6LdRwzImAAAAALRSzuLlYWC-n19EnQLfruyKARIp',
  },
  legacy: {
    webAppBaseUrl: 'https://dev.wingstop.com',
    webAppStoreMenuBaseUrl: 'https://dev.wingstop.com'
  },
  ngfeBaseUrl: 'https://dev.sit.ordering.wingstop.com',
  ping: {
    clientId: '5f70cbfc-0d35-4a14-b2b1-b80986d92ebe',
    clientScope: 'openid ecomm_api',
    stsAuthority: 'https://nplogin.wingstop.com/as',
  },
  smfc: {
    url: 'https://cdn.evgnet.com/beacon/wingstoprestaurants/developmentngfe/scripts/evergage.min.js',
  },
  mapBox: {
    key: 'pk.eyJ1Ijoid2luZ3N0b3AtZGlnaXRhbCIsImEiOiJjbG40dmJwcDEwMzE1MmltcGZldTFiMzlsIn0.SUWlQg6bj3U9_3VNp2Pyyw',
    defaults: {
      style: 'mapbox://styles/mapbox/streets-v12',
      center: { lng: -98.5795, lat: 39.828175 },
      zoom: 3.8,
      mobileZoom: 2.4,
      attributionControl: false
    },
    marker: {
      color: '#070707',
      height: '47px',
      width: '27px'
    },
    activeMarker: {
      color: '#0B582B',
      height: '63px',
      width: '36px'
    },
    popup: {
      offset: 35
    },
    zoomPadding: 0.1,
    minimumDiagonalZoomDistanceKM: 1
  },
  seoMetadata: [
    {
      "route": "",
      "canonical": "",
      "title": "Welcome to Wingstop!  Order Chicken Wings Online",
      "description": "Wingstop - Where flavor gets its Wings! Sauced and tossed in a signature sauce, order your favorite wings online and get them delivered or pickup in-store today!"
    },
    {
      "route": "allergens",
      "canonical": "allergens",
      "title": "Explore Allergens Information for Wingstop Menu | Wingstop",
      "description": "Discover Wingstop's comprehensive allergens information for our delectable menu, ensuring a safe and enjoyable dining experience. Order now at Wingstop!"
    },
    {
      "route": "careers",
      "canonical": "careers",
      "title": "Careers & Job Opportunities | Wingstop",
      "description": "Join the Wingstop family! Explore potential career paths and job opportunities. Join a team full of passionate and dedicated people."
    },
    {
      "route": "get-started",
      "canonical": "get-started",
      "title": "Become a Wingstop Brand Partner! | Wingstop",
      "description": "Learn about what it takes to become a Wingstop brand partner and begin your road to owning and operating your own Wingstop franchise!"
    },
    {
      "route": "gift-cards",
      "canonical": "gift-cards",
      "title": "Wingstop Gift Cards | Wingstop",
      "description": "Wingstop gift cards are the perfect gift for your friends and family, for any occasion! Purchase a gift card from any Wingstop location, or check your balance here."
    },
    {
      "route": "menu",
      "canonical": "menu",
      "title": "View Our Wingstop Menu to Start Your Order! | Wingstop",
      "description": "View the Wingstop menu to explore our chicken wings or tenders! Try them saucy or dry rubbed in our signature flavors, and add fries, sides, and your favorite drink!"
    },
    {
      "route": "order",
      "canonical": "order",
      "title": "Order Wings Online | Chicken Wings Restaurant Near Me | Wingstop",
      "description": "Placing an order at your nearest Wingstop ahead of time is quick, easy, and delicious! Start your order now, available for carryout or delivery!"
    },
    {
      "route": "our-process",
      "canonical": "our-process",
      "title": "Our Franchising Process | Wingstop",
      "description": "Looking to purchase a Wingstop franchise? View our franchising process and get started in this great investment opportunity!"
    },
    {
      "route": "own-a-wingstop",
      "canonical": "own-a-wingstop",
      "title": "Franchise With the Wing Experts! | Wingstop",
      "description": "You can be a part of our success when you buy your very own Wingstop franchise! We have a plan to become a top 10 global restaurant brand and serve the world flavor."
    },
    {
      "route": "why-wingstop",
      "canonical": "why-wingstop",
      "title": "Buy a Wingstop Franchise! | Wingstop",
      "description": "We can see your entrepreneurial spirit, and we have great opportunities for you. See all of the ownderful reasons you should consider franchising with Wingstop!"
    },
    {
      "route": "nutrition",
      "canonical": "nutrition",
      "title": "Nutrition and Allergen Information | Wingstop",
      "description": "This downloadable PDF has all of the important information regarding our ingredients, nutrition facts, and allergen information for all of our Wingstop menu items."
    },
    {
      "route": "faq",
      "canonical": "faq",
      "title": "Frequently Asked Questions | Wingstop",
      "description": "Have a question? The Wing Experts have answered everything you need to know about our menu, order process, and company information, all in one convenient spot."
    },
    {
      "route": "about-us",
      "canonical": "about-us",
      "title": "Our History and Mission | Wingstop",
      "description": "Wingstop was established in 1994 – that's over 20 years of great customer service (and a lot of wings)! Read about our history and mission to serve the world flavor!"
    },
    {
      "route": "flavors",
      "canonical": "flavors",
      "title": "Taste the Delicious Wingstop Sauce Flavors | Wingstop",
      "description": "Discover all of Wingstop's signature sauces. We know it's hard to choose, so try them all and pick your favorites to create a perfect flavor combination!"
    },
    {
      "route": "order/wing-calculator",
      "canonical": "order/wing-calculator",
      "title": "Calculate Your Perfect Wingstop Order! | Wingstop",
      "description": "With this tool, we can help you create a Wingstop order curated specifically for your crew size and hunger levels! Grab your friends and your appetites and try it out!"
    },
    {
      "route": "franchise-support",
      "canonical": "franchise-support",
      "title": "Franchisee Support | Wingstop",
      "description": "If you have any questions regarding the Wingstop franchisee training, real estate or construction process, or operating your Wingstop, you've come to the right place!"
    },
    {
      "route": "success-stories",
      "canonical": "success-stories",
      "title": "Our Entrepreneur Success Stories | Wingstop",
      "description": "Calling all entrepreneurs! If you need another great reason to franchise with Wingstop, read all of the inspiring stories about our passionate, successful franchisees."
    },
    {
      "route": "available-markets",
      "canonical": "available-markets",
      "title": "USA Franchising Opportunities| Wingstop",
      "description": "If you're interested in purchasing a Wingstop franchise in any of our United States locations, view our options and click \"Get Started\" to choose your desired state."
    },
    {
      "route": "international-development",
      "canonical": "international-development",
      "title": "International Franchising Opportunity | Wingstop",
      "description": "If you're interested in purchasing a Wingstop franchise in any of our international locations, view our location options or fill out a Multi-Unit Franchise questionnaire."
    },
    {
      "route": "investment",
      "canonical": "investment",
      "title": "Determine Your Franchising Eligibility | Wingstop",
      "description": "For entrepreneurs considering franchising with Wingstop, learn exactly what it takes to qualify as a franchisee by viewing our full illustration of investment expenses."
    },
    {
      "route": "sitemap",
      "canonical": "sitemap",
      "title": "Sitemap | Wingstop",
      "description": "This is the Wingstop sitemap. Use this sitemap to find your way around wingstop.com. View all of our content and get started on your next Wingstop order."
    },
    {
      "route": "job-search",
      "canonical": "job-search",
      "title": "Job Search - Find a Career at Wingstop | Wingstop",
      "description": "Interested in a career with Wingstop? Search for jobs in your area using our job search engine - the best way to find a job and apply."
    },
    {
      "route": "order/action/carryout",
      "canonical": "order/action/carryout",
      "title": "Order Wings Online for Carryout | Wingstop",
      "description": "Placing an order at your nearest Wingstop ahead of time is quick, easy, and delicious! Start your order now, available for carryout!"
    },
    {
      "route": "order/action/delivery",
      "canonical": "order/action/delivery",
      "title": "Order Wings Online for Delivery | Wingstop",
      "description": "Placing a delivery order at your nearest Wingstop ahead of time is quick, easy, and delicious! Start your order now!"
    },
    {
      "route": "account/login",
      "canonical": "account/login",
      "title": "Log In | Wingstop",
      "description": "Log in to your Wingstop online ordering account to track online orders and quickly reorder."
    },
    {
      "route": "account/signup",
      "canonical": "account/signup",
      "title": "Create Account - Wings Restaurant | Wingstop",
      "description": "Create a Wingstop account to track online orders and save favorites for quick re-ordering."
    },
    {
      "route": "account/forgot-password",
      "canonical": "account/forgot-password",
      "title": "Forgot Password - Wings Restaurant | Wingstop",
      "description": "Reset your Wingstop password to log back in."
    },
    {
      "route": "account/settings",
      "canonical": "account/settings",
      "title": "My Account | Wingstop",
      "description": ""
    },
    {
      "route": "careers/restaurant",
      "canonical": "careers/restaurant",
      "title": "Restaurant Careers | Wingstop",
      "description": "Apply today for a job at corporate and franchise-owned Wingstop restaurants nationwide! Find out how you can Serve the World Flavor as a Wingstop Flavor Expert."
    },
    {
      "route": "careers/global-support-center",
      "canonical": "careers/global-support-center",
      "title": "Global Support Center (HQ) Careers | Wingstop",
      "description": "Looking for a career full of flavor? Come join our corporate office in Dallas, TX to support our Wingstop restaurants worldwide and help in our mission to Serve the World Flavor."
    },
    {
      "route": "order/my-bag",
      "canonical": "order/my-bag",
      "title": "Your Cart | Wingstop",
      "description": ""
    },
    {
      "route": "order/checkout",
      "canonical": "order/checkout",
      "title": "Checkout | Wingstop",
      "description": ""
    },
    {
      "route": "order/confirmation",
      "canonical": "order/confirmation",
      "title": "Confirmation | Wingstop",
      "description": ""
    },
    {
      "route": "order/recent",
      "canonical": "order/recent",
      "title": "My Orders | Wingstop",
      "description": ""
    },
    {
      "route": "order/recent/order-details",
      "canonical": "order/recent/order-details",
      "title": "Order Details | Wingstop",
      "description": ""
    },
    {
      "route": "order/search",
      "canonical": "order",
      "title": "Order Wings Online | Chicken Wings Restaurant Near Me | Wingstop",
      "description": "Placing an order at your nearest Wingstop ahead of time is quick, easy, and delicious! Start your order now, available for carryout or delivery!"
    },
    {
      "route": "accessibility",
      "canonical": "accessibility",
      "title": "Wingstop Commitment to Accessibility | Wingstop",
      "description": "Ensuring inclusivity and accessibility for all at Wingstop - Enjoy our flavorful menu and exceptional service without barriers."
    },
    {
      "route": "california-privacy",
      "canonical": "california-privacy",
      "title": "California Privacy | Wingstop",
      "description": "California Privacy Protection at Wingstop - Your privacy matters to us. Discover how we safeguard your data and personal information."
    },
    {
      "route": "contact-us",
      "canonical": "contact-us",
      "title": "Contact Wingstop Customer Service | Wingstop",
      "description": "Contact Wingstop for Inquiries and Support - Reach out to us for any questions or assistance. We're here to make your experience exceptional!"
    },
    {
      "route": "privacy",
      "canonical": "privacy",
      "title": "Privacy Policy | Wingstop",
      "description": "Protecting Your Privacy: Wingstop's Commitment - Learn how we secure your data and trust."
    },
    {
      "route": "terms-of-use",
      "canonical": "terms-of-use",
      "title": "Terms of Use | Wingstop",
      "description": "Review Wingstop's Terms of Use - Understand our guidelines for website usage."
    }
  ],
  sentry: {
    enableSentry: false,
    enableSentryFeedbackDialog: false,
    dsn: 'https://4cf4a6e15fc97735ef63112e5604c4eb@o4506547460440064.ingest.sentry.io/4506559211438080',
    enableSentryPerformance: true,
    tracesSampleRate: 1.0,
    enableSentryReplays: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [
      "https://secure.quantserve.com/quant.js",
      "https://cdn.cookielaw.org/scripttemplates"
    ],
    maxValueLength: 1000
  },
  googlePay: {
    environment: 'TEST',
    merchantId: '',
    gateway: 'firstdata',
    gatewayMerchantId: '3176752955'
  }
};
